import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiError, ApiFetchStatus } from '../../utils/Api'
import jwt_decode from "jwt-decode"

interface User {
}


interface State extends User {
  token?: string
  tokenExpirationDate?: string
  attemptAuthStatus: ApiFetchStatus
  attemptAuthError?: string
}


const initialState: State = {
  attemptAuthStatus: "idle",
}


/**
 * Auth user with email and password.
 */
export const attemptAuth = createAsyncThunk(
  'auth/attemptAuth',
  async ({ username, password }: { username: string, password: string }, { dispatch }) => {
    const response = await fetch(`${process.env.REACT_APP_AUTH_URL}`, {
      method: "POST",
      body: new URLSearchParams({
        'client_id': `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
        'username': username,
        'password': password,
        'grant_type': 'password',
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })

    if (!response.ok) {
      const body = await response.json()
      throw new ApiError(body.error || "unknown_error")
    }

    const body = await response.json()

    return body['access_token']
  }
)


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    idleAttemptAuthStatus: (state) => {
      state.attemptAuthStatus = "idle"
      state.attemptAuthError = undefined
    },
    logout: () => {
      // Handled in store.ts in rootReducer
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptAuth.pending, (state, action) => {
        state.attemptAuthStatus = "loading"
        state.attemptAuthError = undefined
      })
      .addCase(attemptAuth.fulfilled, (state, action: PayloadAction<string>) => {
        state.attemptAuthStatus = "success"
        state.attemptAuthError = undefined
        state.token = action.payload
        const tokenJson: any = jwt_decode(action.payload)
        state.tokenExpirationDate = new Date(tokenJson.exp * 1000).toISOString()
      })
      .addCase(attemptAuth.rejected, (state, action) => {
        const error = action.error
        state.attemptAuthStatus = "error"
        state.attemptAuthError = error.message
      })
  }
})

export const {
  idleAttemptAuthStatus,
  logout,
} = authSlice.actions

export default authSlice.reducer
