import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface State {
  uploadFileStatus: string,
}


export const initialState:State = {
  uploadFileStatus: "idle",
}

export const fetchUploadFile = createAsyncThunk(
  'cgu/upload',
  async (
    payload: {
      mediaUrl: string,
      data: FormData,
      langCGU: string,
    },
    { getState }
  ) => {
    const state = getState() as { auth: { token: string }}
    const response = await fetch(`${payload.mediaUrl}/cgu/upload/cgu_${payload.langCGU}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: payload.data
    })

    if (!response.ok) {
      throw new Error()
    }
  }
)

const uploadFileSlice = createSlice({
  name: 'upload-file',
  initialState,
  reducers: {
    idleUploadFileStatus: (state) => {
      state.uploadFileStatus = "idle"
    },
  },
  extraReducers: {
    [fetchUploadFile.pending.type]: (state) => {
      state.uploadFileStatus = "loading"
    },
    [fetchUploadFile.fulfilled.type]: (state) => {
      state.uploadFileStatus = "success"
    },
    [fetchUploadFile.rejected.type]: (state) => {
      state.uploadFileStatus = "unknown_error"
    },
  },
})


export default uploadFileSlice.reducer


export const {
  idleUploadFileStatus,
} = uploadFileSlice.actions