import { Button, Row, Col, UploadProps, Radio, notification} from 'antd'
import type {RadioChangeEvent} from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Trans, useTranslation } from 'react-i18next'
import { TiDocumentAdd } from 'react-icons/ti'
import APPLICATIONS from '../../model/Applications'
import { useParams } from 'react-router-dom'
import frFR from 'antd/lib/locale/fr_FR'
import enGB from 'antd/lib/locale/en_GB'
import { idleUploadFileStatus } from './cguSlice'
import './CGU.scss'

interface Props {
  filePDFs: {[lang: string]: File | null}
  setFilePDFs: (file: {[lang: string]: File | null}) => void
  uploadFile: (data : FormData, langCGU: string) => void
}

function UploadCGU({
  filePDFs,
  setFilePDFs,
  uploadFile,
}: Props) {
  const { t } = useTranslation('cgu')
  const dispatch:AppDispatch = useDispatch()
  const currentLanguage = localStorage.getItem("settingsLng") || 'fr'
  const [locale] = useState(currentLanguage === 'fr' ? frFR : enGB)
  const [langCGU, setLangCGU] = useState<string>("fr")
  const uploadFileStatus = useSelector(
    (state: RootState) => state.cgu.uploadFileStatus,
  )
  const auth = useSelector((state: any) => state.auth)
  const { id } = useParams()
  const app = APPLICATIONS.find(a => a.id === id)

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    customRequest: () => {},
    onChange(info) {
      if (info.file.originFileObj?.type !== 'application/pdf') {
        notification.open({
          type: 'error',
          message: t('Only PDF files are accepted.', { ns: 'common' }),
        })
        return
      }
      setFilePDFs({...filePDFs, [langCGU]: info.file.originFileObj as File})
    },
  };

  const changeLangCGU = (lang: RadioChangeEvent) => {
    setLangCGU(lang.target.value)
  }

  useEffect(() => {
    switch (uploadFileStatus) {
      case 'success':
        if (filePDFs[langCGU] && filePDFs[langCGU]?.name) {
          notification.open({
            type: 'success',
            message: 
              <Trans
                i18nKey="UPLOADED_FILE"
                shouldUnescape={true}
                ns="common"
                values={{ uploadedFile: filePDFs[langCGU]?.name || "" }}
              />
          })
          setFilePDFs({...filePDFs, [langCGU]: null})
        }
        break
      case 'unknown_error':
        notification.open({
          type: 'error',
          message: 'Error',
          description: t('An error occurred, please try later.'),
        })
        break
    }
    return () => {
      if (uploadFileStatus !== 'loading' && uploadFileStatus !== 'idle'){
        dispatch(idleUploadFileStatus())
      }
    }
  }, [uploadFileStatus])

  function cancelDrop() {
    setFilePDFs({...filePDFs, [langCGU]: null})
  }

  return (
    <>
      {app && (
        <>
          <Row>
            <Col span={10}>
              <div className="d-flex d-flex-center">
                <Radio.Group defaultValue={currentLanguage} buttonStyle="solid" onChange={changeLangCGU} disabled={uploadFileStatus == 'loading' ? true : false}>
                  <Radio.Button className={`${filePDFs["de"] ? "warning-color" : ""}`} value="de">{t('Deutch', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["en"] ? "warning-color" : ""}`} value="en">{t('English', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["fr"] ? "warning-color" : ""}`} value="fr">{t('French', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["it"] ? "warning-color" : ""}`} value="it">{t('Italian', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["uk"] ? "warning-color" : ""}`} value="uk">{t('Ukrainian', { ns: 'common' })}</Radio.Button>
                </Radio.Group>
              </div>

              <div className="d-flex d-flex-center">
                <div className="w-90">
                  <Dragger {...props} showUploadList={false} className="d-flex mt-1rem" accept='application/pdf'>
                    <p className="ant-upload-text pt-1rem pb-1rem pl-2rem pr-2rem">
                      <strong>
                      {langCGU == "de" && (
                        t('Upload a more recent version of the Terms of Service in deutch')
                      )}
                      {langCGU == "en" && (
                        t('Upload a more recent version of the Terms of Service in english')
                      )}
                      {langCGU == "fr" && (
                        t('Upload a more recent version of the Terms of Service in french')
                      )}
                      {langCGU == "it" && (
                        t('Upload a more recent version of the Terms of Service in italian')
                      )}
                      {langCGU == "uk" && (
                        t('Upload a more recent version of the Terms of Service in ukrainian')
                      )}
                      </strong>
                    </p>
                    <div className="d-flex d-flex-middle d-flex-center pb-1rem">
                      <TiDocumentAdd size="2rem" />
                      <p className="ant-upload-hint mb-0">
                        {t(
                            'Only PDF files are accepted.', { ns: 'common' }
                        )}
                      </p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <h2 className="d-flex d-flex-middle d-flex-center pb-1rem">
                {filePDFs[langCGU] ? (
                  <span className='new-file'><strong>{t('New file to send: ', { ns: 'common' })}</strong>{` ${filePDFs[langCGU]?.name}`}</span>
                ): (
                  <strong>{t('File actually on the server', { ns: 'common' })}</strong>
                )}
              </h2>
              <div className="d-flex d-flex-middle d-flex-center">
                {filePDFs[langCGU] ? (
                  <iframe src={URL.createObjectURL(filePDFs[langCGU] as File)} width="90%" height="700rem"/>
                ): (
                  <iframe src={`${app.mediaUrl}/cgu/cgu_${langCGU}.pdf`} width="90%" height="700rem"/>
                )}
              </div>
            </Col>
          </Row>
          {filePDFs[langCGU] && (
            <Row className='w-100 sticky-row'>
              <div className="w-100 d-flex d-flex-center ">
                <Button onClick={cancelDrop}>
                  {t('Cancel', { ns: 'common' })}
                </Button>
                <div className='ml-1rem'>
                  <Button 
                    type="primary" 
                    onClick={async () => {
                      const data = new FormData()
                      data.append(`cgu_${langCGU}`, filePDFs[langCGU] as File)
                      uploadFile(data, langCGU)
                    }}
                  >
                    {t('Update Terms of Service')}
                  </Button>
                </div>
              </div>
            </Row>
          )}
        </>
      )}
    </>
  )
}

export default UploadCGU