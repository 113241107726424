import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { Button, Modal} from 'antd'
import { useTranslation } from 'react-i18next'
import AppLayout from '../../components/AppLayout/AppLayout'
import { useParams, useNavigate } from 'react-router-dom'
import APPLICATIONS from '../../model/Applications'
import { BiBuildings } from 'react-icons/bi'
import { IoMdArrowBack } from 'react-icons/io'
import UploadCGU from './UploadCGU'
import { fetchUploadFile } from './cguSlice'
import './CGU.scss'



function CGU () {

  const { t } = useTranslation('cgu')
  const navigate = useNavigate()
  const dispatch:AppDispatch = useDispatch()
  const { id } = useParams()

  const app = APPLICATIONS.find(a => a.id === id)

  const LANGUAGES = ['de', 'en', 'fr', 'it', 'uk']
  const [filePDFs, setFilePDFs] = useState<{ [lang: string]: File | null }>({ de: null, en: null, fr: null, it: null, uk: null, });
  const [openIsFileDropModal, setOpenIsFileDropModal] = useState<Boolean>(false)


  function backToApplication(): void {
    if (Object.entries(filePDFs).every(([key, value]) => value === null)) {
      navigate(`/application/${id}`)
    } else {
      setOpenIsFileDropModal(true)
    }
  }


  function continueWhithSave() {
    LANGUAGES.forEach((lang) => {
      if (filePDFs[lang]) {
        const data = new FormData()
        data.append(`cgu_${lang}`, filePDFs[lang] as File)
        uploadFile(data, lang)
      }
    })
    setOpenIsFileDropModal(false)
    navigate(`/application/${id}`)
  }

  function uploadFile(data : FormData, langCGU: string) {
    dispatch(fetchUploadFile({
      mediaUrl: app?.mediaUrl || "",
      data: data,
      langCGU: langCGU,
    }))
  }

          
          
 
  return (
    <AppLayout contentClassName='CGUPage'>
      {app && (
        <>
          <h1 className='ApplicationPage-Name'><BiBuildings size="2em"/> {app.name}</h1>
          <div className='mb-1rem'>
            <Button type='link' className='text-middle' onClick={() => backToApplication()}>
              <IoMdArrowBack/>
              <span>{t('Back', { ns: 'common' })}</span>
            </Button>
          </div>

          <UploadCGU 
            filePDFs={filePDFs} 
            setFilePDFs={setFilePDFs}
            uploadFile={uploadFile}
          />
        </>
      )}

      <Modal
        centered
        title={t("File backups", { ns: 'common'})}
        open={!!openIsFileDropModal}
        onCancel={() => {setOpenIsFileDropModal(false)}}
        destroyOnClose={true}
        footer={[
          <div className='d-flex d-flex-right'>
            <Button 
              onClick={() => {setOpenIsFileDropModal(false)}}
            >
              {t('Cancel', { ns: 'common'})}
            </Button>
            <Button 
              type="primary" 
              onClick={() => {
                setOpenIsFileDropModal(false)
                navigate(`/application/${id}`)
              }}
            >
              {t('Continue without saving', { ns: 'common'})}
            </Button>
            <Button 
              type="primary" 
              onClick={continueWhithSave}
            >
              {t('Save', { ns: 'common'})}
            </Button>
          </div>
        ]}
      >
        <div className='d-flex d-flex-center d-flex-middle'>
          {t("The TOS contain unsaved changes.")}
        </div>
        <div className='d-flex d-flex-center d-flex-middle'>
          {t("Would you like to save your progress?", { ns: 'common'})}
        </div>
      </Modal>
    </AppLayout>    
  )
}

export default CGU