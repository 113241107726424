import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import ApplicationList from "./pages/ApplicationList/ApplicationList"
import Application from "./pages/Application/Application"
import NotFound from "./pages/NotFound/NotFound"
import Auth from "./pages/Auth/Auth"
import RequireAuth from "./pages/Auth/RequireAuth"
import { ConfigProvider } from 'antd'
import frFR from 'antd/lib/locale/fr_FR'
import enGB from 'antd/lib/locale/en_GB'
import { useState } from 'react'
import AutoLogout from "./components/AutoLogout"
import CGU from './pages/CGU/CGU'
import LegalNotice from './pages/LegalNotice/LegalNotice'

function App() {
  const currentLanguage = localStorage.getItem("settingsLng") || 'fr'
  const [locale] = useState(currentLanguage === 'fr' ? frFR : enGB)

  return (
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <AutoLogout>
          <Routes>
            <Route path="/" element={<RequireAuth><ApplicationList /></RequireAuth>} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/application/:id" element={<RequireAuth><Application /></RequireAuth>} />
            <Route path="/cgu/:id" element={<RequireAuth><CGU /></RequireAuth>} />
            <Route path="/legalNotice/:id" element={<RequireAuth><LegalNotice /></RequireAuth>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AutoLogout>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App
