import React, { useEffect, useState } from 'react'
import './Application.scss'
import { Alert, Button, Col, Collapse, Row, Spin, Table, Tooltip, message, Modal, Form, Input, Tag, Select, Switch } from 'antd'
import { Link, useParams } from 'react-router-dom'
import APPLICATIONS from '../../model/Applications'
import AppLayout from '../../components/AppLayout/AppLayout'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { OrganisationUser, fetchAllInfo, idleApplicationStore, OrganizationToCreate, fetchCreateOrganization, Organization, fetchModifyOrganization, fetchDeleteOrganization, fetchAddUser, fetchAttachUser, fetchDeleteUser, fetchModifyUser, fetchEarliestUpcomingRdv } from './applicationSlice'
import { BiBuildings, BiErrorCircle, BiUserCheck } from 'react-icons/bi'
import { IoMdArrowBack } from 'react-icons/io'
import { GrUserAdmin } from 'react-icons/gr'
import { AiFillDelete, AiFillEdit, AiOutlineUserAdd } from 'react-icons/ai'
import LanguageIcon from '../../components/LanguageIcon'
import useSwitchStatus from '../../utils/hooks/useSwitchStatus'
import dayjs from 'dayjs'

const { Panel } = Collapse
const { Option } = Select

class OrganisationUserAdmin implements OrganisationUser {
  email    : string
  firstName: string
  lastName : string
  lang     : string
  admin    : boolean
  organizationId: string

  constructor(email: string, firstName: string, lastName: string, lang: string, admin: boolean, organizationId: string) {
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.admin = admin
    this.lang = lang
    this.organizationId = organizationId
  }
}


function Application() {
  const { t } = useTranslation('application')
  const { id } = useParams()
  const [createOrganizationForm] = Form.useForm()
  const [modifyOrganizationForm] = Form.useForm()
  const [addUserForm] = Form.useForm()
  const [attachUserForm] = Form.useForm()
  const [modifyUserForm] = Form.useForm()
  const dispatch:AppDispatch = useDispatch()
  const organisations = useSelector((state:RootState) => state.application.organisations)
  const earliestUpcomingRdvDate = useSelector((state:RootState) => state.application.earliestUpcomingRdvDate)
  const fetchAllInfoStatus = useSelector((state:RootState) => state.application.fetchAllInfoStatus)
  const fetchCreateOrganizationStatus = useSelector((state:RootState) => state.application.fetchCreateOrganizationStatus)
  const fetchCreateOrganizationError = useSelector((state:RootState) => state.application.fetchCreateOrganizationError)
  const fetchModifyOrganizationStatus = useSelector((state:RootState) => state.application.fetchModifyOrganizationStatus)
  const fetchDeleteOrganizationStatus = useSelector((state:RootState) => state.application.fetchDeleteOrganizationStatus)
  const fetchAddUserStatus = useSelector((state:RootState) => state.application.fetchAddUserStatus)
  const fetchAddUserError = useSelector((state:RootState) => state.application.fetchAddUserError)
  const fetchAttachUserStatus = useSelector((state:RootState) => state.application.fetchAttachUserStatus)
  const fetchAttachUserError = useSelector((state:RootState) => state.application.fetchAttachUserError)
  const fetchModifyUserStatus = useSelector((state:RootState) => state.application.fetchModifyUserStatus)
  const fetchDeleteUserStatus = useSelector((state:RootState) => state.application.fetchDeleteUserStatus)
  const [organizationToCreate, setOrganizationToCreate] = useState<OrganizationToCreate>()
  const [organizationToModify, setOrganizationToModify] = useState<Organization>()
  const [organizationToDelete, setOrganizationToDelete] = useState<Organization>()
  const [userToAdd, setUserToAdd] = useState<{ organizationId: string }>()
  const [userToAttach, setUserToAttach] = useState<{
    organizationId: string
    existingMessage?: boolean
  }>()
  const [userToModify, setUserToModify] = useState<{ organizationId: string }>()
  const [userToDelete, setUserToDelete] = useState<OrganisationUserAdmin>()
  const app = APPLICATIONS.find(a => a.id === id)

  useEffect(() => {
    if (app) {
      dispatch(fetchAllInfo(app))
      dispatch(fetchEarliestUpcomingRdv(app))
    }

    return () => {
      dispatch(idleApplicationStore())
    }
  }, [])

  useSwitchStatus(
    fetchAllInfoStatus,
    () => {},
    () => message.error(t('An error occured. Try to logout and login again.'))
  )

  useSwitchStatus(
    fetchCreateOrganizationStatus,
    () => {
      message.success(t('Organization has been created successfully.'))
      setOrganizationToCreate(undefined)
      createOrganizationForm.resetFields()
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => {
      if (fetchCreateOrganizationError === 'ORGANIZATION_ALREADY_CREATED') {
        message.error(t('This name is already taken by another organization.'))
      } else {
          message.error(t('An error occurred.'))
      }
    }
  )


  useSwitchStatus(
    fetchDeleteOrganizationStatus,
    () => {
      message.success(t('Organization has been deleted successfully.'))
      setOrganizationToDelete(undefined)
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => message.error(t('An error occured.'))
  )

  useSwitchStatus(
    fetchModifyOrganizationStatus,
    () => {
      message.success(t('Organization has been modified successfully.'))
      setOrganizationToModify(undefined)
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => message.error(t('An error occured.'))
  )


  useSwitchStatus(
    fetchAddUserStatus,
    () => {
      message.success(t('User has been added successfully.'))
      addUserForm.setFieldsValue({
        email: "",
        firstName: "", 
        lastName: "",
        lang: "",
        admin: false,
      })
      setUserToAdd(undefined)
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => {
      switch(fetchAddUserError){
        case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANISATION':
          message.error(t(
            'Could not attach participant. He/she is already attached to another organisation.',
          ))
          break
        case 'USER_ALREADY_ATTACHED_TO_ORGANISATION':
          message.error(
            t(
              'Could not attach participant. He/she is already attached to your organisation.',
            ),
          )
          break
        case 'EMAIL_ALREADY_TAKEN':
          attachUserForm.setFieldValue(
            'email',
            addUserForm.getFieldValue('email'),
          )
          setUserToAttach({
            organizationId: userToAdd?.organizationId || "",
            existingMessage: true,
          })
          setUserToAdd(undefined)
          break
        default:  message.error(t('An error occured.'))
      }
    }
  )


  useSwitchStatus(
    fetchModifyUserStatus,
    () => {
      message.success(t('User has been modified successfully.'))
      modifyUserForm.setFieldsValue({
        email: "",
        firstName: "", 
        lastName: "",
        lang: "",
        admin: false,
      })
      setUserToModify(undefined)
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => message.error(t('An error occured.'))
  )


  useSwitchStatus(
    fetchAttachUserStatus,
    () => {
      message.success(t('User has been attached successfully.'))
      attachUserForm.setFieldsValue({
        email: "",
      })
      setUserToAttach(undefined)
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => {
      switch (fetchAttachUserError) {
        case 'USER_ALREADY_ATTACHED_TO_ORGANISATION':
          message.error(
            t(
              'Could not attach participant. He/she is already attached to your organisation.',
            ),
          )
          break
        case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANISATION':
          message.error(
            t(
              'Could not attach participant. He/she is already attached to another organisation.',
            ),
          )
          break

        case 'USER_NOT_FOUND':
          message.error(t('Could not attach participant. His/her account does not exist.'))
          break

        default:
          message.error(t('An error occurred.'))
          break
      }
    }
  )


  useSwitchStatus(
    fetchDeleteUserStatus,
    () => {
      message.success(t('User has been deleted from the organization successfully.'))
      setUserToDelete(undefined)
      if (app) {
        dispatch(fetchAllInfo(app))
      }
    },
    () => message.error(t('An error occured.'))
  )


  async function createOrganization(organization: OrganizationToCreate) {
    try{
      const response = await dispatch(fetchCreateOrganization({ mediaUrl: app?.mediaUrl || "", organization }))
      const id = response.payload
    if(fetchAttachUserError === 'ORGANIZATION_ALREADY_CREATED'){
      setUserToAdd({
        organizationId: id,
      })
    }
    }catch(e){
      console.error('error:', e)
    }
  }


  function modifyOrganization(organization: { name: string, lisences: number, chatActive: boolean }) {
    dispatch(fetchModifyOrganization({
      mediaUrl: app?.mediaUrl || "",
      organizationId: organizationToModify?._id || "",
      organization
    }))
  }


  function deleteOrganization() {
    dispatch(fetchDeleteOrganization({
      mediaUrl: app?.mediaUrl || "",
      organizationId: organizationToDelete?._id || "",
    }))
  }


  function addUser(values: any) {
    dispatch(fetchAddUser({
      mediaUrl: app?.mediaUrl || "",
      organizationId: userToAdd?.organizationId || "",
      user: values,
    }))
  }


  function modifyUser(values: any) {
    dispatch(fetchModifyUser({
      mediaUrl: app?.mediaUrl || "",
      organizationId: userToModify?.organizationId || "",
      user: values,
    }))
  }

  function attachUser(values: any) {
    dispatch(fetchAttachUser({
      mediaUrl: app?.mediaUrl || "",
      organizationId: userToAttach?.organizationId || "",
      user: values,
    }))
  }


  function deleteUser() {
    dispatch(fetchDeleteUser({
      mediaUrl: app?.mediaUrl || "",
      organizationId: userToDelete?.organizationId || "",
      user: { email: userToDelete?.email || "" },
    }))
  }


  const userColumns = [
    {
      title: t('Email'),
      render: (user: OrganisationUserAdmin) => {
        return (<>{user.admin && <Tooltip title={t('Admin')}><GrUserAdmin/></Tooltip>} {user.email}</>)
      },
    },
    {
      title: t('First name'),
      dataIndex: 'firstName',
    },
    {
      title: t('Last name'),
      dataIndex: 'lastName',
    },
    {
      title: t('Actions'),
      className: "ApplicationPage-UsersTable-ActionColumn",
      render: (user: OrganisationUserAdmin) => {
        return (
          <>
            <Button
              type='link'
              size='small' 
              onClick={() => { 
                setUserToModify(user)
                modifyUserForm.setFieldsValue({
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  lang: user.lang,
                  admin: user.admin,
                })
              }}
            >
              <AiFillEdit/>
            </Button>
            <Button type='link' size='small' danger onClick={() => setUserToDelete(user)}><AiFillDelete/></Button>
          </>
        )
      }
    },
  ]


  return (
    <AppLayout contentClassName='ApplicationPage'>
      {app ? (
        <>
          <h1 className='ApplicationPage-Container'>
            <span className='ApplicationPage-Name'><BiBuildings size="2em"/>{app.name}</span>
            <span>{earliestUpcomingRdvDate && <p className='mb-0 ml-1rem'>{t('Next meeting')}: {dayjs(earliestUpcomingRdvDate).format('DD/MM/YYYY HH:mm')}</p>}</span>
          </h1>
          <div className='mb-1rem d-flex d-flex-middle g-1em'>
            <Button type='link'><Link to="/" className='text-middle'><IoMdArrowBack/>{t('Back to the list')}</Link></Button>
            <Button type='primary' onClick={() => setOrganizationToCreate({})}>
              {t("Create organization")}
            </Button>
            <Link to={`/cgu/${id}`}><Button className='ml-1rem' type='primary'>{t("CGU")}</Button></Link>
            <Link to={`/legalNotice/${id}`}><Button className='ml-1rem' type='primary'>{t("Legal notices")}</Button></Link>
          </div>
          {fetchAllInfoStatus === "loading" && <Spin size='large'/>}
          {fetchAllInfoStatus === "loading" && <BiErrorCircle type='error' size="2em" color='#ff4d4f' />}
          {fetchAllInfoStatus === "success" && (
            <Row gutter={24}>
              {organisations?.map(org => {
                return (
                  <Col md={24} lg={12} className='mb-24px' key={org._id}>
                    <Collapse
                      bordered={false}
                      defaultActiveKey={[]}
                    >
                      <Panel 
                        header={(<>
                          <span className='ApplicationPage-OrganizationName'>{org.name}</span>
                          <Button 
                            type='primary' 
                            size='small' 
                            className='mr-1rem'
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setOrganizationToModify(org)
                              modifyOrganizationForm.setFieldsValue(org)
                            }}
                          >
                            {t("Modify")}
                          </Button>
                          <Button 
                            type='primary'
                            size='small'
                            danger
                            className='mr-1rem'
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setOrganizationToDelete(org)
                            }}
                          >
                            {t("Delete")}
                          </Button>
                          <Tooltip title={t("Add user")}>
                            <Button 
                              type='primary'
                              size='small'
                              className='mr-1rem'
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setUserToAdd({
                                  organizationId: org._id,
                                })
                              }}
                            >
                              <AiOutlineUserAdd/>
                            </Button>
                          </Tooltip>
                          <Tooltip title={t("Attach user")}>
                            <Button 
                              type='primary'
                              size='small'
                              className='mr-1rem'
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setUserToAttach({
                                  organizationId: org._id
                                })
                              }}
                            >
                              <BiUserCheck/>
                            </Button>
                          </Tooltip>
                          <Tag 
                            color={
                              org.users.length / org.lisences >= 1 && "red"
                              || org.users.length / org.lisences >= 0.8 && "orange"
                              || "green"
                            }>{org.users.length} / {org.lisences}</Tag>
                          </>
                        )}
                        key="1"
                      >
                        <Table
                          columns={userColumns}
                          dataSource={org.users.map(u => new OrganisationUserAdmin(
                            u.email,
                            u.firstName,
                            u.lastName,
                            u.lang,
                            org.admins.includes(u.email),
                            org._id
                          ))}
                          pagination={false}
                          rowKey="email"
                        />
                      </Panel>
                    </Collapse>
                  </Col>
                )
              })}
            </Row>
          )}
        </>
      )
      : (
        <Alert message={t('Environment not exist')}/>
      )}

      <Modal
        title={t("Create organization")}
        open={!!organizationToCreate}
        okText={t("Create")}
        onOk={() => createOrganizationForm.submit() }
        confirmLoading={fetchCreateOrganizationStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => {
          setOrganizationToCreate(undefined);
          createOrganizationForm.resetFields();
        }}
        destroyOnClose={true}
      >
        <Form
          form={createOrganizationForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={createOrganization}
          autoComplete="off"
          disabled={fetchCreateOrganizationStatus === 'loading'}
        >
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[{ required: true, message: t('Please input organization name') || '' }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label={t('Lisences')}
            name="lisences"
            rules={[{ required: true, message: t('Please input number of licenses') || '' }]}
          >
            <Input type="number" min="0" />
          </Form.Item>

          <Form.Item
            label={t('Chat active')}
            name="chatActive"
            valuePropName="checked"
          >
            <Switch/>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Modify organization")}
        open={!!organizationToModify}
        okText={t("Modify")}
        onOk={() => modifyOrganizationForm.submit() }
        confirmLoading={fetchModifyOrganizationStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => setOrganizationToModify(undefined)}
        destroyOnClose={true}
      >
        <Form
          form={modifyOrganizationForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ username: '', password: '' }}
          onFinish={modifyOrganization}
          autoComplete="off"
          disabled={fetchModifyOrganizationStatus === 'loading'}
        >
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[{ required: true, message: t('Please input organization name') || '' }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label={t('Lisences')}
            name="lisences"
            rules={[{ required: true, message: t('Please input number of licenses') || '' }]}
          >
            <Input type="number" min="0" />
          </Form.Item>

          <Form.Item
            label={t('Chat enabled')}
            name="chatActive"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Delete organization")}
        open={!!organizationToDelete}
        okText={t("Delete")}
        onOk={deleteOrganization}
        confirmLoading={fetchDeleteOrganizationStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => setOrganizationToDelete(undefined)}
        destroyOnClose={true}
      >
        <p>
        <Trans
          i18nKey="DELETE_ORGANIZATION"
          shouldUnescape={true}
          ns="application"
          values={{ name: organizationToDelete?.name }}
          components={{ bold: <strong /> }}
        />
        </p>
      </Modal>

      <Modal
        title={t("Add user")}
        open={!!userToAdd}
        okText={t("Add")}
        onOk={() => addUserForm.submit()}
        confirmLoading={fetchAddUserStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => {
          setUserToAdd(undefined);
          addUserForm.resetFields();
        }}
        destroyOnClose={true}
      >
        <Form
          form={addUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addUser}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[{ required: true, message: t('Please input an email') || "" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[{ required: true, message: t('Please input a first name') || "" },
                    { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || ""} 
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[{ required: true, message: t('Please input a last name') || "" },
                    { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || ""}
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') || "" }]}
            initialValue="en"
          >
            <Select>
              <Option value="en">
                <LanguageIcon language="en" /> <span>English</span>
              </Option>
              <Option value="fr">
                <LanguageIcon language="fr" /> <span>Français</span>
              </Option>
            </Select>
          </Form.Item>

          <Form.Item label={t('Admin')} name="admin" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title={t("Modify user")}
        open={!!userToModify}
        okText={t("Modify")}
        onOk={() => modifyUserForm.submit()}
        confirmLoading={fetchModifyUserStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => setUserToModify(undefined)}
        destroyOnClose={true}
      >
        <Form
          form={modifyUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={modifyUser}
        >
          <Form.Item
            label={t('Email')}
            name="email"
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[{ required: true, message: t('Please input a first name') || "" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[{ required: true, message: t('Please input a last name') || "" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') || "" }]}
            initialValue="en"
          >
            <Select>
              <Option value="en">
                <LanguageIcon language="en" /> <span>English</span>
              </Option>
              <Option value="fr">
                <LanguageIcon language="fr" /> <span>Français</span>
              </Option>
            </Select>
          </Form.Item>

          <Form.Item label={t('Admin')} name="admin" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title={t('Attach user')}
        open={!!userToAttach}
        okText={t("Attach")}
        onOk={() => attachUserForm.submit()}
        confirmLoading={fetchAddUserStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => {
          setUserToAttach(undefined);
          addUserForm.resetFields();
          attachUserForm.resetFields();
        }}
        destroyOnClose={true}
      >
        {userToAttach?.existingMessage && (
          <Alert
            message={t('This account already exists. Do you want to attach it to this organization?')}
            className="mb-1rem"
            type="info"
          />
        )}

        <Form
          form={attachUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={attachUser}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[{ required: true, message: t('Please input an email') || "" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Delete user")}
        open={!!userToDelete}
        okText={t("Delete")}
        onOk={deleteUser}
        confirmLoading={fetchDeleteUserStatus === "loading"}
        cancelText={t("Cancel")}
        onCancel={() => setUserToDelete(undefined)}
        destroyOnClose={true}
      >
        <p>
          <Trans
            i18nKey="DELETE_USER"
            shouldUnescape={true}
            ns="application"
            values={{ email: userToDelete?.email, firstName: userToDelete?.firstName, lastName: userToDelete?.lastName, nameOrganization: (organisations?.find(org => org._id === userToDelete?.organizationId))?.name }}
            components={{ bold: <strong /> }}
          />
        </p>
      </Modal>

    </AppLayout>
  )
}

export default Application
