import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiFetchStatus } from '../../utils/Api'
import { ApplicationInterface } from '../../model/Applications'


export interface OrganisationSummary {
  name              : string,
  frontUrl          : string,
  fetchSummaryStatus: ApiFetchStatus
  organisations?    : {
    _id  : string
    name : string
    count: number
  }[]
}


interface State {
  [id:string]: OrganisationSummary
}


const initialState: State = {
  
}


/**
 * Auth user with email and password.
 */
export const fetchSummaryApplication = createAsyncThunk(
  'applicationList/fetchSummaryApplication',
  async (payload: ApplicationInterface, { getState, dispatch }) => {
    const state = getState() as any

    const response = await fetch(`${payload.mediaUrl}/admin/organisation-summary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      throw new Error()
    }

    const body = await response.json()

    return body
  }
)


const applicationList = createSlice({
  name: 'applicationList',
  initialState,
  reducers: {
    idleApplicationList: (state) => {
      state = {}
    },
  },

  extraReducers: {
    [fetchSummaryApplication.pending.type]: (state, action) => {
      state[action.meta.arg['id']] = {
        name: action.meta.arg['name'],
        frontUrl: action.meta.arg['frontUrl'],
        fetchSummaryStatus: "loading",
      }
    },
    [fetchSummaryApplication.fulfilled.type]: (state, action) => {
      state[action.meta.arg['id']] = {
        name: action.meta.arg['name'],
        frontUrl: action.meta.arg['frontUrl'],
        fetchSummaryStatus: "success",
        organisations: action.payload
      }
    },
    [fetchSummaryApplication.rejected.type]: (state, action) => {
      state[action.meta.arg['id']] = {
        name: action.meta.arg['name'],
        frontUrl: action.meta.arg['frontUrl'],
        fetchSummaryStatus: "error",
      }
    },
  },
})

export const {
  idleApplicationList,
} = applicationList.actions

export default applicationList.reducer
