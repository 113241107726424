import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiError, ApiFetchStatus } from '../../utils/Api'


export interface OrganisationUser {
  email    : string
  firstName: string
  lastName : string
  lang     : string
}


export interface OrganizationToCreate {
  name?      : string,
  lisences?  : number,
  chatActive?: boolean,
}


export interface Organization {
  _id  : string
  name : string
  users: OrganisationUser[]
  admins: string[]
  lisences: number
  chatActive: boolean
}


interface State {
  fetchAllInfoStatus: ApiFetchStatus
  fetchAllInfoError?: string
  organisations?: Organization[]
  fetchCreateOrganizationStatus: ApiFetchStatus
  fetchCreateOrganizationError?: string
  fetchModifyOrganizationStatus: ApiFetchStatus
  fetchModifyOrganizationError?: string
  fetchDeleteOrganizationStatus: ApiFetchStatus
  fetchDeleteOrganizationError?: string
  fetchAddUserStatus: ApiFetchStatus
  fetchAddUserError?: string
  fetchAttachUserStatus: ApiFetchStatus
  fetchAttachUserError?: string
  fetchModifyUserStatus: ApiFetchStatus
  fetchModifyUserError?: string
  fetchDeleteUserStatus: ApiFetchStatus
  fetchDeleteUserError?: string
  fetchEarliestUpcomingRdvStatus: ApiFetchStatus
  fetchEarliestUpcomingRdvError?: string
  earliestUpcomingRdvDate?: string
}


const initialState: State = {
  fetchAllInfoStatus: "idle",
  fetchCreateOrganizationStatus: "idle",
  fetchModifyOrganizationStatus: "idle",
  fetchDeleteOrganizationStatus: "idle",
  fetchAddUserStatus: "idle",
  fetchAttachUserStatus: "idle",
  fetchModifyUserStatus: "idle",
  fetchDeleteUserStatus: "idle",
  fetchEarliestUpcomingRdvStatus: 'idle'
}


/**
 * Auth user with email and password.
 */
export const fetchAllInfo = createAsyncThunk(
  'application/fetchAllInfo',
  async (payload: { mediaUrl: string, id: string }, { getState, dispatch }) => {
    const state = getState() as any

    const response = await fetch(`${payload.mediaUrl}/admin/organisation-all-info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      throw new Error()
    }

    const body = await response.json()

    return body
  }
)


export const fetchCreateOrganization = createAsyncThunk(
  'application/fetchCreateOrganization',
  async (
    payload: { mediaUrl: string, organization: OrganizationToCreate },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${payload.mediaUrl}/admin/organisation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify({
        name: payload.organization.name,
        lisences: payload.organization.lisences,
        chatActive: payload.organization.chatActive,
      })
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
    const body = await response.json()
    return body._id
  }
)


export const fetchModifyOrganization = createAsyncThunk(
  'application/fetchModifyOrganization',
  async (
    payload: { 
      mediaUrl: string, 
      organizationId: string,
      organization: { name: string, lisences: number, chatActive: boolean } 
    },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${payload.mediaUrl}/admin/organisation/${payload.organizationId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify({
        name: payload.organization.name,
        lisences: payload.organization.lisences,
        chatActive: payload.organization.chatActive,
      })
    })
    
    if (!response.ok) {
      throw new Error()
    }
  }
)


export const fetchDeleteOrganization = createAsyncThunk(
  'application/fetchDeleteOrganization',
  async (
    payload: { 
      mediaUrl: string, 
      organizationId: string,
    },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${payload.mediaUrl}/admin/organisation/${payload.organizationId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      throw new Error()
    }
  }
)


/**
 * Add user.
 */
export const fetchAddUser = createAsyncThunk(
  'organisation/fetchAddUser',
  async (
    { mediaUrl, organizationId, user }: 
      {
        mediaUrl: string
        organizationId: string
        user: { email: string, firstName: string, lastName: string, lang: string, admin: boolean }
      },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${mediaUrl}/admin/organisation/${organizationId}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


/**
 * Add user.
 */
export const fetchModifyUser = createAsyncThunk(
  'organisation/fetchModifyUser',
  async (
    { mediaUrl, organizationId, user }: 
      {
        mediaUrl: string
        organizationId: string
        user: { email: string, firstName: string, lastName: string, lang: string, admin: boolean }
      },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${mediaUrl}/admin/organisation/${organizationId}/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


/**
 * Attach user.
 */
 export const fetchAttachUser = createAsyncThunk(
  'organisation/fetchAttachUser',
  async (
    { mediaUrl, organizationId, user }: 
      {
        mediaUrl: string
        organizationId: string
        user: { email: string }
      },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${mediaUrl}/admin/organisation/${organizationId}/user/attach`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


/**
 * Delete user.
 */
export const fetchDeleteUser = createAsyncThunk(
  'organisation/fetchDeleteUser',
  async (
    { mediaUrl, organizationId, user }: 
      {
        mediaUrl: string
        organizationId: string
        user: { email: string }
      },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${mediaUrl}/admin/organisation/${organizationId}/user`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      throw new Error()
    }
  }
)


export const fetchEarliestUpcomingRdv = createAsyncThunk(
  'application/fetchEarliestUpcomingRdv',
  async (payload: { mediaUrl: string, id: string }, { getState, dispatch }) => {
    const state = getState() as any

    const response = await fetch(`${payload.mediaUrl}/admin/get-earliest-rdv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      throw new Error()
    }

    const body = await response.json()

    return body
  }
)


const application = createSlice({
  name: 'application',
  initialState,
  reducers: {
    idleApplicationStore: (state) => {
      state.fetchAllInfoStatus = "idle"
      state.fetchAllInfoError = undefined
      state.organisations = undefined
      state.fetchCreateOrganizationStatus = "idle"
      state.fetchCreateOrganizationError = undefined
      state.fetchModifyOrganizationStatus = "idle"
      state.fetchModifyOrganizationError = undefined
      state.fetchDeleteOrganizationStatus = "idle"
      state.fetchDeleteOrganizationError = undefined
      state.fetchAddUserStatus = "idle"
      state.fetchAddUserError = undefined
      state.fetchAttachUserStatus = "idle"
      state.fetchAttachUserError = undefined
      state.fetchModifyUserStatus = "idle"
      state.fetchModifyUserError = undefined
      state.fetchDeleteUserStatus = "idle"
      state.fetchDeleteUserError  = undefined
      state.fetchEarliestUpcomingRdvStatus = "idle"
      state.fetchEarliestUpcomingRdvError = undefined
      state.earliestUpcomingRdvDate = undefined
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInfo.pending, (state, action) => {
        state.fetchAllInfoStatus = "loading"
      })
      .addCase(fetchAllInfo.fulfilled, (state, action) => {
        state.fetchAllInfoStatus = "success"
        state.organisations = action.payload
      })
      .addCase(fetchAllInfo.rejected, (state, action) => {
        state.fetchAllInfoStatus = "error"
      })
      .addCase(fetchCreateOrganization.pending, (state, action) => {
        state.fetchCreateOrganizationStatus = "loading"
      })
      .addCase(fetchCreateOrganization.fulfilled, (state, action) => {
        state.fetchCreateOrganizationStatus = "success"
      })
      .addCase(fetchCreateOrganization.rejected, (state, action) => {
        state.fetchCreateOrganizationStatus = "error"
        state.fetchCreateOrganizationError = action.error.message
      })
      .addCase(fetchModifyOrganization.pending, (state, action) => {
        state.fetchModifyOrganizationStatus = "loading"
      })
      .addCase(fetchModifyOrganization.fulfilled, (state, action) => {
        state.fetchModifyOrganizationStatus = "success"
      })
      .addCase(fetchModifyOrganization.rejected, (state, action) => {
        state.fetchModifyOrganizationStatus = "error"
      })
      .addCase(fetchDeleteOrganization.pending, (state, action) => {
        state.fetchDeleteOrganizationStatus = "loading"
      })
      .addCase(fetchDeleteOrganization.fulfilled, (state, action) => {
        state.fetchDeleteOrganizationStatus = "success"
      })
      .addCase(fetchDeleteOrganization.rejected, (state, action) => {
        state.fetchDeleteOrganizationStatus = "error"
      })
      .addCase(fetchAddUser.pending, (state, action) => {
        state.fetchAddUserStatus = "loading"
      })
      .addCase(fetchAddUser.fulfilled, (state, action) => {
        state.fetchAddUserStatus = "success"
      })
      .addCase(fetchAddUser.rejected, (state, action) => {
        state.fetchAddUserStatus = "error"
        state.fetchAddUserError = action.error.message
      })
      .addCase(fetchModifyUser.pending, (state, action) => {
        state.fetchModifyUserStatus = "loading"
      })
      .addCase(fetchModifyUser.fulfilled, (state, action) => {
        state.fetchModifyUserStatus = "success"
      })
      .addCase(fetchModifyUser.rejected, (state, action) => {
        state.fetchModifyUserStatus = "error"
        state.fetchModifyUserError = action.error.message
      })
      .addCase(fetchAttachUser.pending, (state, action) => {
        state.fetchAttachUserStatus = "loading"
      })
      .addCase(fetchAttachUser.fulfilled, (state, action) => {
        state.fetchAttachUserStatus = "success"
      })
      .addCase(fetchAttachUser.rejected, (state, action) => {
        state.fetchAttachUserStatus = "error"
        state.fetchAttachUserError = action.error.message
      })

      .addCase(fetchDeleteUser.pending, (state, action) => {
        state.fetchDeleteUserStatus = "loading"
      })
      .addCase(fetchDeleteUser.fulfilled, (state, action) => {
        state.fetchDeleteUserStatus = "success"
      })
      .addCase(fetchDeleteUser.rejected, (state, action) => {
        state.fetchDeleteUserStatus = "error"        
      })

      .addCase(fetchEarliestUpcomingRdv.pending, (state, action) => {
        state.fetchEarliestUpcomingRdvStatus = "loading"
      })
      .addCase(fetchEarliestUpcomingRdv.fulfilled, (state, action) => {
        state.fetchEarliestUpcomingRdvStatus = "success"
        state.earliestUpcomingRdvDate = action.payload.startDate
      })
      .addCase(fetchEarliestUpcomingRdv.rejected, (state, action) => {
        state.fetchEarliestUpcomingRdvError = "error"        
      })
  }
})

export const {
  idleApplicationStore,
} = application.actions

export default application.reducer
