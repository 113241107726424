import { Button, Row, Col, UploadProps, Radio, notification} from 'antd'
import type {RadioChangeEvent} from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Trans, useTranslation } from 'react-i18next'
import { TiDocumentAdd } from 'react-icons/ti'
import APPLICATIONS from '../../model/Applications'
import { useParams } from 'react-router-dom'
import frFR from 'antd/lib/locale/fr_FR'
import enGB from 'antd/lib/locale/en_GB'
import { fetchUploadFile, idleUploadFileStatus } from './legalNoticeSlice'
import './LegalNotice.scss'

interface Props {
  filePDFs: {[lang: string]: File | null}
  setFilePDFs: (file: {[lang: string]: File | null}) => void
  uploadFile: (data : FormData, langCGU: string) => void
}

function UploadLegalNotice({
  filePDFs,
  setFilePDFs,
  uploadFile,
}: Props) {
  const { t } = useTranslation('legalNotice')
  const dispatch:AppDispatch = useDispatch()
  const currentLanguage = localStorage.getItem("settingsLng") || 'fr'
  const [locale] = useState(currentLanguage === 'fr' ? frFR : enGB)
  const [langLegalNotice, setLangLegalNotice] = useState<string>("fr")
  const uploadFileStatus = useSelector(
    (state: RootState) => state.legalNotice.uploadFileStatus,
  )
  const auth = useSelector((state: any) => state.auth)
  const { id } = useParams()
  const app = APPLICATIONS.find(a => a.id === id)

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    customRequest: () => {},
    onChange(info) {
      if (info.file.originFileObj?.type !== 'application/pdf') {
        notification.open({
          type: 'error',
          message: t('Only PDF files are accepted.', { ns: 'common' }),
        })
        return
      }
      setFilePDFs({...filePDFs, [langLegalNotice]: info.file.originFileObj as File})
    },
  };

  const changeLangLegalNotice = (lang: RadioChangeEvent) => {
    setLangLegalNotice(lang.target.value)
  }

  useEffect(() => {
    switch (uploadFileStatus) {
      case 'success':
        if (filePDFs[langLegalNotice] && filePDFs[langLegalNotice]?.name) {
          notification.open({
            type: 'success',
            message: 
              <Trans
                i18nKey="UPLOADED_FILE"
                shouldUnescape={true}
                ns="common"
                values={{ uploadedFile: filePDFs[langLegalNotice]?.name || "" }}
              />
          })
          setFilePDFs({...filePDFs, [langLegalNotice]: null})
        }
        break
      case 'unknown_error':
        notification.open({
          type: 'error',
          message: 'Error',
          description: t('An error occurred, please try later.'),
        })
        break
    }
    return () => {
      if (uploadFileStatus !== 'loading' && uploadFileStatus !== 'idle'){
        dispatch(idleUploadFileStatus())
      }
    }
  }, [uploadFileStatus])

  function cancelDrop() {
    setFilePDFs({...filePDFs, [langLegalNotice]: null})
  }


  return (
    <>
      {app && (
        <>
          <Row>
            <Col span={10}>
              <div className="d-flex d-flex-center">
                <Radio.Group defaultValue={currentLanguage} buttonStyle="solid" onChange={changeLangLegalNotice} disabled={uploadFileStatus == 'loading' ? true : false}>
                  <Radio.Button className={`${filePDFs["de"] ? "warning-color" : ""}`} value="de">{t('Deutch', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["en"] ? "warning-color" : ""}`} value="en">{t('English', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["fr"] ? "warning-color" : ""}`} value="fr">{t('French', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["it"] ? "warning-color" : ""}`} value="it">{t('Italian', { ns: 'common' })}</Radio.Button>
                  <Radio.Button className={`${filePDFs["uk"] ? "warning-color" : ""}`} value="uk">{t('Ukrainian', { ns: 'common' })}</Radio.Button>
                </Radio.Group>
              </div>

              <div className="d-flex d-flex-center">
                <div className="w-90">
                  <Dragger {...props} showUploadList={false} className="d-flex mt-1rem" accept='application/pdf'>
                    <p className="ant-upload-text pt-1rem pb-1rem pl-2rem pr-2rem">
                      <strong>
                      {langLegalNotice == "de" && (
                        t('Upload a more recent version of the legal notices in deutch')
                      )}
                      {langLegalNotice == "en" && (
                        t('Upload a more recent version of the legal notices in english')
                      )}
                      {langLegalNotice == "fr" && (
                        t('Upload a more recent version of the legal notices in french')
                      )}
                      {langLegalNotice == "it" && (
                        t('Upload a more recent version of the legal notices in italian')
                      )}
                      {langLegalNotice == "uk" && (
                        t('Upload a more recent version of the legal notices in ukrainian')
                      )}
                      </strong>
                    </p>
                    <div className="d-flex d-flex-middle d-flex-center pb-1rem">
                      <TiDocumentAdd size="2rem" />
                      <p className="ant-upload-hint mb-0">
                        {t('Only PDF files are accepted.', { ns: 'common' })}
                      </p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <h2 className={`d-flex d-flex-middle d-flex-center pb-1rem ${filePDFs[langLegalNotice] ? "new-file" : ""}`}>
                {filePDFs[langLegalNotice] ? (
                  <span><strong>{t('New file to send: ', { ns: 'common' })}</strong>{` ${filePDFs[langLegalNotice]?.name}`}</span>
                ): (
                  <strong>{t('File actually on the server', { ns: 'common' })}</strong>
                )}
              </h2>
              <div className="d-flex d-flex-middle d-flex-center">
                {filePDFs[langLegalNotice] ? (
                  <iframe src={URL.createObjectURL(filePDFs[langLegalNotice] as File)} width="90%" height="700rem"/>
                ): (
                  <iframe src={`${app.mediaUrl}/legal-notice/legal_notice_${langLegalNotice}.pdf`} width="90%" height="700rem"/>
                )}
              </div>
            </Col>
          </Row>
          {filePDFs[langLegalNotice] && (
            <Row className='w-100 sticky-row'>
              <div className="w-100 d-flex d-flex-center ">
                <Button onClick={cancelDrop}>
                  {t('Cancel', { ns: 'common' })}
                </Button>
                <div className='ml-1rem'>
                  <Button 
                    type="primary" 
                    onClick={async () => {
                      const data = new FormData()
                      data.append(`legal_notice_${langLegalNotice}`, filePDFs[langLegalNotice] as File)
                      uploadFile(data, langLegalNotice)
                    }}
                  >
                    {t('Update legal notices')}
                  </Button>
                </div>
              </div>
            </Row>
          )}
        </>
      )}
    </>
  )
}

export default UploadLegalNotice