import React, { useEffect, useState, useCallback } from 'react'
import { RootState } from '../store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatSeconds } from '../services/SecondFormatter'
import useInterval from '../utils/hooks/useInterval'
import { logout } from '../pages/Auth/authSlice'
import { Button, Modal } from 'antd'

export default function TimeComponent(){
  const [remainingSeconds, setRemainingSeconds] = useState<number | null | undefined>(null)
  const [modalVisible, setModalVisible] = useState(false)
  const tokenExpiration = useSelector((state: RootState) => state.auth.tokenExpirationDate)
  const { t } = useTranslation('application')
  const dispatch = useDispatch()

  function calulateExpirationTime(): number | undefined{
    if(!tokenExpiration) return
    const tokenExpires = new Date(tokenExpiration)
    const currentDate = new Date()
    //const tokenExpirer = new Date(tokenExpires.getTime() - 59.50*60000) //set to 30 seconds to test
    const secondsRemaining = Math.floor((tokenExpires.getTime() - currentDate.getTime()) / 1000)
    return secondsRemaining
  }

  useInterval({
    interval: 1000,
    action: () => setRemainingSeconds(calulateExpirationTime())
  })

  useEffect(() => {
    if (remainingSeconds !== null && remainingSeconds !== undefined && remainingSeconds <= 0 ) {
      setModalVisible(true)
    }
  }, [remainingSeconds])

  const handleOk = useCallback(() => {
    setModalVisible(false)
    dispatch(logout())
  }, [dispatch])

  return (
    <>
      {remainingSeconds && <span>{t('Session expiration:')} {formatSeconds(remainingSeconds)}</span>}
      <Modal
        centered
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
        title={<div style={{textAlign: 'center'}}>{t('You have been disconnected')}</div>}
        width={400}
        open={modalVisible}
        onOk={handleOk}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" onClick={handleOk}>
              {t('Reconnect to the page')}
            </Button>
          </div>
        ]}
      >
        <div style={{textAlign: 'center'}}>
          {t("Your session has expired")}
        </div>
      </Modal>
    </>
  )
}
