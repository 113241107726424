export interface ApplicationInterface {
  id:string
  name: string
  frontUrl: string
  mediaUrl: string
}

const APPLICATIONS: ApplicationInterface[] = [
  {
    id: 'dev',
    name: "Auxamed dev",
    frontUrl : "https://webconferencing-dev.temma.care",
    mediaUrl : "https://media.auxamed-dev.temma.care",
  },
  {
    id: 'rec',
    name: "Auxamed recette",
    frontUrl : "https://auxamed-rec.temma.care",
    mediaUrl : "https://media.auxamed-rec.temma.care",
  },
  {
    id: 'fribourg-rec',
    name: "Auxamed Fribourg recette",
    frontUrl : "https://antalgie.auxamed-rec.temma.care",
    mediaUrl : "https://media.antalgie.auxamed-rec.temma.care",
  },
  {
    id: 'auxaview-rec',
    name: "Auxaview recette",
    frontUrl : "https://auxaview-rec.temma.care",
    mediaUrl : "https://media.auxaview-rec.temma.care",
  },
  {
    id: 'auxaview',
    name: "Auxaview prod",
    frontUrl : "https://auxaview.com",
    mediaUrl : "https://media.auxaview.com",
  },
  {
    id: 'auxamed',
    name: "Auxamed prod",
    frontUrl : "https://auxamed.com",
    mediaUrl : "https://media.auxamed.com",
  },
  {
    id: 'fribourg-prod',
    name: "Auxamed Fribourg prod",
    frontUrl : "https://fribourg.auxamed.com",
    mediaUrl : "https://media.fribourg.auxamed.com",
  },

  {
    id: 'precos-prod',
    name: "Auxamed Precos prod",
    frontUrl : "https://precos.auxamed.com",
    mediaUrl : "https://media.precos.auxamed.com",
  },
  {
    id: 'circamed-prod',
    name: "Auxamed Circamed prod",
    frontUrl : "https://circamed.auxamed.com",
    mediaUrl : "https://media.circamed.auxamed.com",
  },
  {
    id: 'decidelife-prod',
    name: "Auxaview Decidelife prod",
    frontUrl : "https://decidelife.auxaview.com/",
    mediaUrl : "https://media.decidelife.auxaview.com",
  },
  {
    id: 'weedoo-prod',
    name: "Auxaview WeeDoo prod",
    frontUrl : "https://weedoo.auxaview.com/",
    mediaUrl : "https://media.weedoo.auxaview.com",
  },
  {
    id: 'intechfloor-prod',
    name: "Auxaview Intechfloor prod",
    frontUrl : "https://intechfloor.auxaview.com/",
    mediaUrl : "https://media.intechfloor.auxaview.com",
  },
  {
    id: 'inspire-prod',
    name: "Auxamed Inspire prod",
    frontUrl : "https://inspire.auxamed.com/",
    mediaUrl : "https://media.inspire.auxamed.com",
  },
  {
    id: 'lifetmpsu-prod',
    name: "Auxamed Lifetmpsu prod",
    frontUrl : "https://lifecaremedicine.auxamed.com/",
    mediaUrl : "https://media.lifetmpsu.auxamed.com",
  },
  {
    id: 'fondationuniversiteparis-saclay',
    name: "Fondation Paris Saclay",
    frontUrl : "https://fondationuniversiteparis-saclay.auxaview.com/",
    mediaUrl : "https://media.fondationuniversiteparis-saclay.auxaview.com",
  },
]

if (process.env.REACT_APP_LOCAL_APPLICATION === "true") {
  APPLICATIONS.unshift({
    id: 'local',
    name: "LOCAL FOR DEV",
    frontUrl : "http://localhost:3000",
    mediaUrl : "http://localhost:4443",
  })
}

export default APPLICATIONS
