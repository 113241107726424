import React, { useState, forwardRef, useImperativeHandle, Ref, useEffect, useRef } from 'react'
import {
  useIdleTimerContext
} from 'react-idle-timer'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { formatSeconds } from '../services/SecondFormatter'


export interface AutoLogoutModalInterface {
  openModal: () => void
  close: () => void
}

const AutoLogoutModal = forwardRef(
  (props: {}, ref: Ref<AutoLogoutModalInterface>) => {
    useImperativeHandle(ref, () => ({
      openModal: () => {
        setModalVisible(true)
      },
      close: close
    }))
    const { t } = useTranslation('application')
    const { activate, getRemainingTime } = useIdleTimerContext()
    const [remaining, setRemaining] = useState<number>(0)
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const interval = useRef<NodeJS.Timeout | null>(null)
    const token = useSelector((state:RootState) => state.auth.token)

    function close(){
      if(modalVisible){
        setModalVisible(false)
        activate()
      }
    }

    useEffect(() => {
      interval.current = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)
  
      return () => {
        interval.current && clearInterval(interval.current)
      }
    })


    return (
      <Modal
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
        title={<div style={{textAlign: 'center'}}>{t('Are you still here?')}</div>}
        width={400}
        open={modalVisible}
        onCancel={close}
      >
        <div style={{textAlign: 'center'}}>
          {(remaining > 0 && token) &&
            t("You will be disconnected in:") + formatSeconds(remaining)
          }
          {(remaining === 0 || !token) && t("You have been disconnected due to inactivity")}
        </div>
      </Modal>
    )
  },
)

AutoLogoutModal.displayName = 'AutoLogoutModal'
export default AutoLogoutModal
