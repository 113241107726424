import React, { useEffect, useMemo, useState } from 'react'
import './ApplicationList.scss'
import APPLICATIONS from '../../model/Applications'
import Layout from '../../components/AppLayout/AppLayout'
import { Button, Spin, Table, Tooltip, AutoComplete } from 'antd'
import { FiExternalLink } from 'react-icons/fi'
import { MdOutlineManageSearch } from 'react-icons/md'
import { BiErrorCircle } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { OrganisationSummary, fetchSummaryApplication, idleApplicationList } from './applicationListSlice'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'


function ApplicationList() {
  const { t } = useTranslation('applicationList')
  const dispatch:AppDispatch = useDispatch()
  const list = useSelector((state: RootState) => state.applicationList)
  const [selectedOrg, setSelectedOrg] = useState("")
  const [filteredList, setFilteredList] = useState<[string, OrganisationSummary][]>([])

  useEffect(() => {
    APPLICATIONS.map(app => dispatch(fetchSummaryApplication(app)))

    return () => {
      dispatch(idleApplicationList())
    }
  }, [])

  useEffect(() => {
    const filterListByOrg = ([id, summary]: [string, OrganisationSummary]) => {
      return selectedOrg ? summary.name.includes(selectedOrg) : true
    }
  
    const filteredList = Object.entries(list).filter(filterListByOrg)
  
    setFilteredList(filteredList)
  }, [selectedOrg, list]);

  const envNames = useMemo(() => {
    return Object.values(list).map((summary: OrganisationSummary) => summary.name)
  }, [list])

  const columns = [
    {
      title: 
      <div className='d-flex d-flex-middle g-0_5em'>
        {t('Title')}  
        <AutoComplete
          allowClear
          style={{ width: 200 }}
          options={envNames.map(name => ({ value: name }))}
          onSelect={value => setSelectedOrg(value)}
          onChange={value => setSelectedOrg(value || "")}
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().includes(inputValue.toUpperCase())
          }
        />


      </div>,
      width : 480,
      render: (item: any) => {
        const summary = item[1] as OrganisationSummary
        return summary.name
      }
    },
    {
      title: t('Organizations'),
      render: (item: any) => {
        const summary = item[1] as OrganisationSummary

        return (
          <>
            {summary.fetchSummaryStatus === "loading" && <Spin/>}
            {summary.fetchSummaryStatus === "error" && <Tooltip title={t('Could not fetch data')}><BiErrorCircle type='error' size="2em" color='#ff4d4f' /></Tooltip>}
            {summary.fetchSummaryStatus === "success" && (
              <>
                {summary.organisations?.map((org) => {
                  return <Tooltip title={org.name}><div className='ApplicationList-Organisation'>{org.count}</div></Tooltip>
                })}
              </>
            )}
          </>
        )
      }
    },
    {
      title: t('Actions'),
      render: (item: any) => {
        const id = item[0] as string
        const summary = item[1] as OrganisationSummary

        return (
          <>
            <Tooltip title={t('Open the site')}><Button type='link' href={summary.frontUrl} target="_blank"><FiExternalLink size="1.5em"/></Button></Tooltip>
            <Tooltip title={t('Manage')}><Button type='link'><Link to={`/application/${id}`}><MdOutlineManageSearch size="1.5em"/></Link></Button></Tooltip>
          </>
        )
      }
    },
  ]

  return (
    <Layout contentClassName='ApplicationList'>
      <div className='TableAutoComplete'>
        <Table
          dataSource={filteredList}
          columns={columns}
          pagination={false}
          rowKey={item => item[0]}
        />
        <div className="autocomplete-overlay">

        </div>
      </div>
    </Layout>
  )
}

export default ApplicationList
