import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import appLayoutEN from './trans/AppLayout/en.json'
import appLayoutFR from './trans/AppLayout/fr.json'
import applicationEN from './trans/Application/en.json'
import applicationFR from './trans/Application/fr.json'
import applicationListEN from './trans/ApplicationList/en.json'
import applicationListFR from './trans/ApplicationList/fr.json'
import authEN from './trans/Auth/en.json'
import authFR from './trans/Auth/fr.json'
import legalNoticeEN from './trans/LegalNotice/en.json'
import legalNoticeFR from './trans/LegalNotice/fr.json'
import cguEN from './trans/CGU/en.json'
import cguFR from './trans/CGU/fr.json'
import commonEN from './trans/Common/en.json'
import commonFR from './trans/Common/fr.json'


export const resources = {
  en: {
    auth: authEN,
    application: applicationEN,
    applicationList: applicationListEN,
    layout: appLayoutEN,
    legalNotice: legalNoticeEN,
    cgu: cguEN,
    common: commonEN,
  },
  fr: {
    auth: authFR,
    application: applicationFR,
    applicationList: applicationListFR,
    layout: appLayoutFR,
    legalNotice: legalNoticeFR,
    cgu: cguFR,
    common: commonFR,
  },
} as const;


i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("settingsLng") || 'fr',
    fallbackLng: 'en',
    resources,
    nsSeparator: false
  })


i18n.on("languageChanged", lng => {
  localStorage.setItem("settingsLng", lng)
})
